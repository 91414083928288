import "./App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import routes from "./routes";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router forceRefresh={true}>
          <Switch>
            {routes
              .filter((r) => !r.layout)
              .map((r) =>
                r.private ? (
                  <PrivateRoute
                    exact={r.exact}
                    path={r.path}
                    key={r.path}
                    component={r.component}
                  />
                ) : (
                  <Route
                    exact={r.exact}
                    path={r.path}
                    key={r.path}
                    component={r.component}
                  />
                )
              )}
          </Switch>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
