import React from "react";
import Button from "@material-ui/core/Button";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../../constants/dictionary";

import useStyles from "./CustomButton.styles";

const CustomButton = ({
  variant,
  size,
  clickHandler,
  text,
  disabled,
  type = "button",
}) => {
  const classes = useStyles();
  let buttonClass;
  let sizeClass;

  if (variant === BUTTON_VARIANTS.GREEN) {
    buttonClass = classes.greenButton;
  } else if (variant === BUTTON_VARIANTS.RED) {
    buttonClass = classes.redButton;
  } else if (variant === BUTTON_VARIANTS.WHITE) {
    buttonClass = classes.whiteButton;
  } else if (variant === BUTTON_VARIANTS.DISABLED) {
    buttonClass = classes.disabledButton;
  }

  if (size === BUTTON_SIZES.SMALL) {
    sizeClass = classes.buttonSmall;
  } else if (size === BUTTON_SIZES.MEDIUM) {
    sizeClass = classes.buttonMedium;
  } else if (size === BUTTON_SIZES.LARGE) {
    sizeClass = classes.buttonLarge;
  }

  return (
    <Button
      disabled={disabled}
      className={`${buttonClass} ${sizeClass}`}
      onClick={clickHandler}
      type={type}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
