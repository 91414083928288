import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { List } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

import colors from "../../constants/colors";

const useStyle = makeStyles((theme) => ({
  item: {
    marginTop: "2vh",
    marginBottom: "2vh",
  },
  noUnderline: {
    textDecoration: "none",
  },
}));

function ListItemLink(props) {
  const classes = useStyle();

  return (
    <Link to={props.to} className={classes.noUnderline}>
      <ListItem className={classes.item} button {...props} />
    </Link>
  );
}

const ListItemWhiteText = withStyles({
  root: {
    color: colors.white,
  },
})(ListItemText);

// TODO: better redirect
export const MainListItems = () => {
  return (
    <List>
      <ListItemLink to={"/dashboard/cultivationtypes"}>
        <ListItemWhiteText primary="Typy upraw" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/users"}>
        <ListItemWhiteText primary="Użytkownicy" />
      </ListItemLink>
      <ListItemLink style={{ marginBottom: 0 }} to={"/dashboard/push"}>
        <ListItemWhiteText primary="Powiadomienia push" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/QR"}>
        <ListItemWhiteText primary="Kody QR" />
      </ListItemLink>
    </List>
  );
};

export const logoutListItems = (
  <List style={{ height: 50, margin: 0, padding: 0 }}>
    <ListItemLink style={{ height: 50, margin: 0, padding: 0 }} to={"/logout"}>
      <ListItemIcon>
        <PowerSettingsNewIcon style={{ color: colors.white }} />
      </ListItemIcon>
      <ListItemWhiteText primary="Wyloguj" />
    </ListItemLink>
  </List>
);
