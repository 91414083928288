import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const StyledFormTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 50,
        boxShadow: "1px 1px 1px 1px #ccc",
        bordrer: 0,
      },
      "&:hover fieldset": {
        borderColor: colors.green,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.green,
      },
    },
    "& label.Mui-focused": {
      color: colors.green,
    },
  },
})(TextField);

export default StyledFormTextField;
