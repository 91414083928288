import LoginScreen from "./screens/Login/LoginScreen";
import Dashboard from "./screens/Dashboard/Dashboard";
import Logout from "./components/Logout.js";

const routes = [
  {
    path: "/login",
    component: LoginScreen,
    exact: true,
  },
  {
    path: "/logout",
    component: Logout,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/*",
    component: Dashboard,
    exact: true,
    private: true,
  },
  {
    path: "/",
    component: LoginScreen,
  },
];

export default routes;
