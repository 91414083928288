import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../screens/Login/LoginSreen.breakpoints";

const SuccessSnackBar = ({ open, close, text }) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    close();
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: matches ? "center" : "right",
      }}
    >
      <Alert onClose={handleClose} severity="success">
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SuccessSnackBar;
