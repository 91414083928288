import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "3%",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "50%",
    marginBottom: "1.5%",
  },
  searchContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    alignItems: "center",
    width: "100%",
    height: "50%",
  },
  headerText: {
    fontFamily: "Montserrat",
    marginTop: "1%",
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
    },
    width: "45%",
  },
  box: {
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5%",
      width: "45%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "30%",
      marginTop: ".5%",
      marginRight: "2%",
    },
  },
  rightBox: {
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5%",
      marginRight: "3%",
      "& label": {
        fontSize: 14,
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "30%",
      marginTop: ".5%",
      marginLeft: "20%",
    },
  },
  addButton: {
    marginTop: "5%",
    marginRight: "1%",
  },
}));

export default useStyles;
