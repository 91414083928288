import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, makeStyles, Box, CircularProgress } from "@material-ui/core";

import CommonModal from "../common/modals/CommonModal";
import StyledFormTextField from "../common/textFields/StyledFormTextField";

import styles from "./EditQRcodeWindow.styles";
import { COMMON_MODAL_TEXTS, MODAL_TYPE } from "../../constants/dictionary";
import { useGetUsers } from "../../query/useUserQuery";
import { useEditQR } from "../../query/useQRQuery";

const useStyles = makeStyles(styles);

const EditQRcodeWindow = ({ isOpen, close, data, handleError }) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState("");

  const { users: usersInfo, isFetchingUsers } = useGetUsers(1, 2000);
  const { editQR } = useEditQR(handleError);

  useEffect(() => {
    if (data.userEmail) {
      setSelectedUser(data);
    } else setSelectedUser("");
  }, [data]);

  const handleChange = (e, n) => {
    setSelectedUser(n);
  };

  const submit = (e) => {
    e.preventDefault();
    editQR({ beeGrowUserId: selectedUser.id, uuid: data.uuid });
  };

  return (
    <CommonModal
      disabled={!selectedUser}
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_QR_CODE_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        {!isFetchingUsers ? (
          <Box className={classes.box}>
            <Autocomplete
              value={selectedUser}
              options={usersInfo}
              getOptionLabel={(option) => option.username || ""}
              getOptionSelected={(option, value) =>
                option.id === value.id || !value
              }
              renderInput={(params) => (
                <StyledFormTextField
                  {...params}
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  label="Wybierz użytkownika"
                  variant="outlined"
                  required
                  shrink="true"
                />
              )}
              onChange={(e, n) => handleChange(e, n)}
            />
          </Box>
        ) : (
          <div className={classes.container}>
            <CircularProgress color="secondary" className={classes.loader} />
          </div>
        )}
      </Grid>
    </CommonModal>
  );
};

export default EditQRcodeWindow;
