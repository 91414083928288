import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  primary: {
    fontSize: 16,
    color: colors.white,
    overflowWrap: "break-word",
  },
  secondary: {
    fontSize: 10,
    color: colors.quickSilver,
  },
  button: {
    fontSize: 12,
    color: colors.white,
  },
}));

export default useStyles;
