import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Box,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import useForm from "../../hooks/useForm";
import { auth } from "../../utils/api";
import tokenUtils from "../../utils/tokenUtils";
import StyledFormTextField from "../common/textFields/StylesFormTextField";
import CustomButton from "../common/buttons/CustomButton";
import ErrorBox from "../common/ErrorBox";
import styles from "./LoginForm.styles";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";
import { validateEmail } from "../../utils/validation";

const useStyles = makeStyles(styles);

const LoginForm = () => {
  const [credentialsInput, setCredentialsInput] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { handleChange, values } = useForm({ email: "", password: "" });
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [date, setDate] = useState(new Date().getTime());

  useEffect(() => {
    const token = tokenUtils.getToken();
    if (token) setAuthorized(true);
    return function cleanup() {
      setAuthorized(false);
      setRedirect(false);
    };
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    auth
      .login(values.email, values.password)
      .then((res) => {
        const { jwt } = res.data;
        tokenUtils.setToken(jwt);
        setRedirect(true);
      })
      .catch((error) => {
        setDate(new Date().getTime());
        setLoginError(true);
        switch (error.response.data) {
          case "User not found!":
            setErrorText("Nie znaleziono użytkownika!");
            break;
          case "Login credentials are incorrect!":
            setPasswordError(true);
            setErrorText("Niepoprawny email lub hasło!");
            break;
          case "Account is not activated!":
            setErrorText("Konto nie zostało aktywowane!");
            break;
          case "Account is blocked!":
            setErrorText("Konto zostało zablokowane!");
            break;
          default:
            setPasswordError(true);
            setErrorText("Niepoprawny email lub hasło");
            break;
        }
      });
  };

  const animationHandler = () => (e) => {
    if (e.animationName === "mui-auto-fill") {
      setCredentialsInput(true);
    }
    if (e.animationName === "mui-auto-fill-cancel") {
      setCredentialsInput(false);
    }
  };

  useEffect(() => {
    if (values.email?.length > 0 && values.password?.length > 0)
      setCredentialsInput(true);
    else setCredentialsInput(false);
  }, [values]);

  useEffect(() => {
    if (validateEmail(values.email) || values.email === "")
      setEmailError(false);
    else setEmailError(true);
  }, [values.email]);

  return (
    <form autoComplete="off">
      <Box className={classes.root}>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.loginText} variant="h4">
              Zaloguj się
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <StyledFormTextField
                fullWidth={true}
                InputLabelProps={{ classes: { root: classes.label } }}
                error={emailError}
                helperText={emailError ? "Niepoprawny e-mail" : ""}
                name="email"
                onChange={handleChange("email")}
                label="E-MAIL"
                variant="outlined"
                required
                shrink="true"
                InputProps={{
                  onAnimationStart: animationHandler(),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                shrink="true"
                error={passwordError}
                name="password"
                onChange={handleChange("password")}
                label="HASŁO"
                variant="outlined"
                type={isVisible ? "text" : "password"}
                required
                InputProps={{
                  onAnimationStart: animationHandler(),
                  endAdornment: (
                    <InputAdornment position="end">
                      {isVisible ? (
                        <VisibilityOffIcon
                          className={classes.cursors}
                          onClick={() => {
                            setIsVisible(!isVisible);
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.cursors}
                          onClick={() => {
                            setIsVisible(!isVisible);
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box2}>
              {loginError && <ErrorBox text={errorText} key={date} />}
              <CustomButton
                disabled={!credentialsInput || emailError}
                variant={
                  !credentialsInput || emailError
                    ? BUTTON_VARIANTS.DISABLED
                    : BUTTON_VARIANTS.GREEN
                }
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={submit}
                text="Zaloguj się"
                type="submit"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {(redirect || authorized) && <Redirect to="/dashboard" />}
    </form>
  );
};

export default LoginForm;
