export const queryKeys = {
  USERS: "USERS",
  ONE_USER: "ONE_USER",
  CURRENT_USER: "CURRENT_USER",
  ONE_CULTIVATION: "ONE_CULTIVATION",
  CULTIVATION_TYPES: "CULTIVATION_TYPES",
  CULTIVATION_NAMES: "CULTIVATION_NAMES",
  ONE_QR_CODE: "ONE_QR_CODE",
  QR_CODES: "QR_CODES",
};
