import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthScreen from "../../screens/AuthScreen/AuthScreen";
import tokenUtils from "../../utils/tokenUtils";

function PrivateRoute({ component: Component, ...rest }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const token = tokenUtils.getToken();
    if (token) setIsLoggedIn(true);
    setIsLoaded(true);

    return function cleanup() {
      setIsLoaded(false);
    };
  }, [Component]);

  return isLoaded ? (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  ) : (
    <AuthScreen />
  );
}

export default PrivateRoute;
