import tokenUtils from "../utils/tokenUtils";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { useLogout } from "../query/useUserQuery";

const Logout = () => {
  const { logout } = useLogout();
  useEffect(() => {
    tokenUtils.clearToken();
    logout();
  });

  return <Redirect to="/login" />;
};

export default Logout;
