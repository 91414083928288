import { makeStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  greenButton: {
    background: colors.green,
    borderRadius: 50,
    border: 0,
    color: colors.white,
    padding: "0 30px",
    "&:hover": {
      background: colors.greenLight,
    },
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  redButton: {
    background: colors.carmine,
    borderRadius: 50,
    border: 0,
    color: colors.white,
    padding: "0 30px",
    "&:hover": {
      background: colors.carmineDark,
    },
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  whiteButton: {
    background: colors.white,
    borderRadius: 50,
    border: 0,
    color: colors.black,
    padding: "0 30px",
    "font-family": "Montserrat",
    "font-size": "14px",
    "font-weight": "bold",
    "&:hover": {
      background: colors.white,
    },
  },
  disabledButton: {
    background: colors.htmlGray,
    borderRadius: 50,
    border: 0,
    color: colors.black,
    padding: "0 30px",
    "&:hover": {
      background: colors.htmlGray,
    },
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  buttonSmall: {
    height: "100%",
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  buttonMedium: {
    height: 50,
    width: "60%",

    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  buttonLarge: {
    height: 50,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
}));

export default useStyles;
