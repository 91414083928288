import colors from "../../constants/colors";

const styles = {
  email: {
    width: "15%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  blocked: {
    width: "15%",
    textAlign: "center",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  delete: {
    color: colors.carmine,
  },
  iconLabelRed: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginTop: "2%",
    color: colors.carmine,
    marginLeft: 0,
    padding: 12,
    borderRadius: 10,
    fontSize: 18,
    width: "70%",
    minWidth: 175,
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "#d5d9d9",
    },
  },
  iconLabelGreen: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginTop: "2%",
    color: colors.green,
    marginLeft: 0,
    padding: 12,
    borderRadius: 10,
    fontSize: 18,
    width: "70%",
    minWidth: 175,
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "#d5d9d9",
    },
  },
  iconLabel: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
    marginTop: "2%",
  },
  cell: {
    "font-weight": "bold",
    "font-family": "Montserrat",
  },
  row: {
    transform: "all 0.25s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      cursor: "pointer",
    },
    unlock: {
      color: colors.green,
    },

    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  },
};

export default styles;
