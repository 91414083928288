export const SERVER_ERROR_MESSAGES = {
  // SETTINGS
  COULD_NOT_CHANGE_SETTINGS: "Błąd serwera, nie udało się zmienić ustawień",

  // USERS
  COULD_NOT_EDIT_USER: "Błąd serwera, nie udało się edytować użytkownika",
  COULD_NOT_ADD_USER: "Błąd serwera, nie udało się dodać użytkownika",
  COULD_NOT_DOWNLOAD_USERS: "Błąd serwera, nie udało się pobrać użytkowników",
  COULD_NOT_BLOCK_USER: "Błąd serwera, nie udało się zablokować użytkownika",
  COULD_NOT_UNBLOCK_USER: "Błąd serwera, nie udało się odblokować użytkownika",
  COULD_NOT_FIND_USER: "Błąd serwera, nie udało się znaleźć użytkownika",

  // QR CODES
  COULD_NOT_EDIT_QR_CODE: "Błąd serwera, nie udało się edytować kodu",
  COULD_NOT_CREATE_QR_CODE: "Błąd serwera, nie udało się stworzyć kodu",
  COULD_NOT_LOAD_QR_CODE_IMAGE:
    "Błąd serwera, nie udało się załadować obrazu kodu",
  COULD_NOT_DELETE_QR_CODE: "Błąd serwera, nie udało się usunąć kodu",
  COULD_NOT_DOWNLOAD_QR_CODES: "Błąd serwera, nie udało się pobrać kodów",
  COULD_NOT_DOWNLOAD_QR_CODE: "Błąd serwera, nie udało się pobrać kodu",
  COULD_NOT_FIND_QR_CODE: "Błąd serwera, nie znaleziono kodu",

  // CROPS
  COULD_NOT_FIND_CROP: "Błąd serwera, nie udało się znaleźć uprawy",
  COULD_NOT_EDIT_CROP: "Błąd serwera, nie udało się edytować uprawy",
  COULD_NOT_DELETE_CROP: "Błąd serwera, nie udało się usunąć uprawy",
  COULD_NOT_ADD_CROP: "Błąd serwera, nie udało się dodać uprawy",
  COULD_NOT_DOWNLOAD_CROPS: "Błąd serwera, nie udało się pobrać listy upraw",
  CROP_EXISTS: "Uprawa o podanej nazwie już istnieje",

  // GENERAL
  COULD_NOT_DOWNLOAD_DATA: "Błąd serwera, nie udało się pobrać danych",
  SERVER_ERROR: "Błąd serwera",
  INSUFFICIENT_CREDENTIALS:
    "Niewystarczające uprawnienia.\nZa chwilę nastąpi wylogowanie.",
};

export const ERROR_MESSAGES = {
  COULD_NOT_CONNECT_TO_SERVER: "Błąd połączenia z serwerem",
  COULD_NOT_FIND_CROPS_TYPES: "Nie znaleziono typów upraw",
  COULD_NOT_DOWNLOAD_CROPS: "Nie udało się pobrać listy upraw",
  COULD_NOT_UPLOAD_PHOTO: "Nie udało się wgrać zdjęcia",
  COULD_NOT_LOAD_PHOTO: "Nie udało się załadować zdjęcia",
  INCORRECT_PRICES: "Podane ceny są nieprawidłowe",
  COULD_NOT_EDIT_QUANTITY: "Nie udało się edytować ilości",
  COULD_NOT_DELETE_ITEM: "Nie udało się usunąć przedmiotu",
  COULD_NOT_ADD_ITEM: "Nie udało się dodać przedmiotu",
  COULD_NOT_MODIFY_VALUE: "Nie udało się zmodyfikować wartości",
};

export const CULTIVATION_TYPE_ERRORS = {
  INVALID_VALUE: "Niepoprawna wartość",
  MANUAL_ENDED:
    "Zakres konfiguracji dla zbiorów ręcznych został już zakończony wartością nieokreśloną.",
  COMBINE_ENDED:
    "Zakres konfiguracji dla zbiorów maszynowych został już zakończony wartością nieokreśloną.",
  MANUAL_INCORRECT_RANGE:
    "Podana wartość jest mniejsza lub równa końcowi zakresu konfiguracji dla zbiorów ręcznych.",
  COMBINE_INCORRECT_RANGE:
    "Podana wartość jest mniejsza lub równa końcowi zakresu konfiguracji dla zbiorów maszynowych.",
  INITIAL_RANGE: "Dolna wartość zakresu musi zaczynać się od 0",
};

export const SUCCESS_MESSAGES = {
  SUCCESS_ADD_CROP: "Pomyślnie dodano typ uprawy",
  SUCCESS_EDIT_CROP: "Pomyślnie edytowano typ uprawy",
  SUCCESS_DELETE_CROP: "Pomyślnie usunięto typ uprawy",
};

export const BUTTON_VARIANTS = {
  GREEN: "green",
  RED: "red",
  WHITE: "white",
  DISABLED: "grey",
};

export const BUTTON_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const COMMON_MODAL_TEXTS = {
  QR_TITLE: "Czy napewno chcesz usunąć kod QR?",
  QR_CONTENT: `Usunięcie kodu spowoduje jego trwałe usunięcie z systemu i nie
  będzie możliwości jego przywrócenia.`,

  USER_TITLE_BLOCK: "Czy napewno chcesz zablokować użytkownika",
  USER_TITLE_UNBLOCK: "Czy napewno chcesz odblokować użytkownika",
  USER_CONTENT_BLOCK:
    "Zablokowanie użytkownika odbierze mu możliwość korzystania ze strony",
  USER_CONTENT_UNBLOCK:
    "Odblokowanie użytkownika zwróci mu możliwość korzystania ze strony",

  DEVELOPMENT_STAGE_TITLE: "Czy na pewno chcesz usunąć stan rozwoju?",

  FIELD_TYPE_TITLE: "Czy na pewno chcesz usunąć typ uprawy?",

  GENERAL_CONTENT: "Tej akcji nie można cofnąć!",

  GLOBAL_SETTINGS_TITLE: "Zmień globalne ustawienia",

  EDIT_QR_CODE_TITLE: "Edytuj produkt lub użytkownika przypisanego do kodu",
  ADD_QR_CODE_TITLE: "Czy chcesz dodać nowy kod QR?",
  ADD_QR_CODE_CONTENT: `Dodanie kodu spowoduje jego pojawienie się w bazie, będzie
  możliwość jego usunięcia`,

  EDIT_USER_TITLE: "Dostosuj dane użytkownika",
  ADD_USER_TITLE: "Dodaj nowego użytkownika",

  ADD_CULTIVATION_TYPE_TITLE: "Dodaj nowy typ uprawy",
  EDIT_CULTIVATION_TYPE_TITLE: "Edytuj typ uprawy",

  ADD_CULTIVATION_STAGE_TITLE: "Dodaj nowy stan rozwoju",
  EDIT_CULTIVATION_STAGE_TITLE: "Edytuj stan rozwoju",
};

export const HARVEST_MESSAGES = {
  null: "Nieokreślony",
  true: "Ręczny",
  false: "Maszynowy",
};

export const MODAL_TYPE = {
  EDIT: "edit",
  DELETE: "delete",
  ADD: "add",
};

export const COMMON_MODAL_CONFIRM_BUTTON_TEXTS = {
  edit: "Edytuj",
  add: "Dodaj",
  delete: "Tak",
};

export const COMMON_MODAL_REJECT_BUTTON_TEXTS = {
  edit: "Anuluj",
  add: "Anuluj",
  delete: "Nie",
};

export const API_URL =
  process.env.REACT_APP_API_URL || "http://91.228.197.83:10002";
