import React, { useState } from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import styles from "./QRInfoBox.styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DownloadButton from "../common/buttons/DownloadButton";
import EditQRcodeWindow from "./EditQRcodeWindow";
import CommonModal from "../common/modals/CommonModal";

import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";
import { downloadQR } from "../../utils/downloadQR";
import QRCode from "react-qr-code";
import { useDeleteQR } from "../../query/useQRQuery";
import Hive from "./Hive";
import NoHive from "./NoHive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(styles);

const QRInfoBox = ({ info, handleDeleteError, handleEditError }) => {
  const history = useHistory();
  const classes = useStyles(styles);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { deleteQR } = useDeleteQR(handleDeleteError);

  const deleteCode = (qrId) => () => {
    deleteQR(qrId);
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row">
        <Grid
          container
          item
          xs={matches ? 12 : 7}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs={12} align="center">
            <QRCode
              id="QRCode"
              value={info?.uuid}
              style={{
                height: "auto",
                width: "80%",
                maxWidth: 400,
              }}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Box className={classes.downloadBox}>
              <DownloadButton onClick={() => downloadQR(info.uuid)}>
                Pobierz kod QR
              </DownloadButton>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <Box className={classes.headerMargin}>
                <Typography className={classes.header}>
                  Informacje o kodzie:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Przypisany"
                    secondary={info?.hivePlaced ? <Hive /> : <NoHive />}
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Użytkownik"
                    secondary={
                      <Typography className={classes.listText}>
                        {info?.userEmail ? info.userEmail : "Brak"}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={deleteCode(info.id)}
        title={COMMON_MODAL_TEXTS.QR_TITLE}
        content={COMMON_MODAL_TEXTS.QR_CONTENT}
      />
      <EditQRcodeWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={info}
        handleError={handleEditError}
      />
    </div>
  );
};

export default QRInfoBox;
