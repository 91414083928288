import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    box: {
      margin: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    productBox: {
      margin: "2%",
    },
    dataBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxDesc: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxPrices: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
      display: "flex",
      padding: "2%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "flex-wrap": "wrap",
    },
    root: {
      backgroundColor: colors.white,
      borderRadius: "30px",
      [theme.breakpoints.down("sm")]: {
        height: "80vh",
      },
      [theme.breakpoints.up("sm")]: {
        height: "80vh",
      },
      padding: "5%",
    },
    cursors: {
      cursor: "pointer",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
      color: colors.oxfordBlue,
    },
    rootGrid: {
      maxWidth: "95%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15%",
      },
      backgroundColor: theme.palette.background.paper,
    },
    headerText: {
      fontSize: 30,
      fontWeight: 600,
      "font-family": "Montserrat",
    },
    subtext: {
      overflowWrap: "break-word",
      fontSize: 40,
      fontWeight: 500,
      "font-family": "Montserrat",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
      "align-self": "center",
    },
    active: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "text-align": "center",
      "font-size": "25px",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "250px",
        height: "250px",
      },
      padding: 0,
      backgroundColor: colors.white,
    },
    icon: {
      width: "100%",
      height: "100%",
      fill: colors.oxfordBlue,
    },
    iconLabelRed: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.carmine,
      marginLeft: 0,
      marginBottom: "2%",
      borderRadius: 10,
      fontSize: 18,
      width: "80%",
      backgroundColor: "#f7f7f7",
      "&:hover": {
        backgroundColor: "#d5d9d9",
      },
    },
    iconLabelGreen: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.green,
      marginLeft: 0,
      marginBottom: "2%",
      borderRadius: 10,
      fontSize: 18,
      width: "80%",
      backgroundColor: "#f7f7f7",
      "&:hover": {
        backgroundColor: "#d5d9d9",
      },
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    desBox: { margin: 0 },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
    RightBox: {
      marginTop: "5%",
      marginBottom: "5%",
    },
    margin: {
      marginBottom: "10%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",
    },
    buttonIcon: { marginRight: 5 },
  };
};

export default styles;
