import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const StyledFormTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 50,
        boxShadow: "1px 1px 1px 1px #ccc",
        border: 0,
        "box-shadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      },
      "&:hover fieldset": {
        borderColor: colors.green,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.green,
      },
      inputLabel: {
        fontSize: 10,
      },
    },
    "& label.Mui-focused": {
      color: colors.green,
    },
    "& label": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
  },
}))(TextField);

export default StyledFormTextField;
