import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  ThemeProvider,
  makeStyles,
  Typography,
} from "@material-ui/core";

import theme from "../Login/LoginSreen.breakpoints";
import styles from "./QRInfoScreen.styles";

import QRInfoBox from "../../components/QR/QRInfoBox";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import { useGetOneQR } from "../../query/useQRQuery";
import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const QRInfoScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_QR_CODE);
  };

  const handleDeleteError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_QR_CODE);
  };

  const handleEditError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_QR_CODE);
  };

  const { qr, isFetchingQR, isError } = useGetOneQR(id, handleError);
  return (
    <ThemeProvider theme={theme}>
      {!isFetchingQR ? (
        !isError ? (
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <QRInfoBox
                info={qr}
                handleDeleteError={handleDeleteError}
                handleEditError={handleEditError}
              />
            </Grid>
          </Grid>
        ) : (
          <div className={classes.container}>
            <Typography>{errorText}!</Typography>
          </div>
        )
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}

      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default QRInfoScreen;
