import React from "react";
import { Animated } from "react-animated-css";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import styles from "./AuthScreen.styles";
import authIcon from "../../assets/images/authIcon.jpg";

const useStyles = makeStyles(styles);

const AuthScreen = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      className={classes.root}
    >
      <Grid item xs={12} align="center" className={classes.center}>
        <Animated
          animationIn="tada"
          isVisible={true}
          animationInDuration={1000}
        >
          <img src={authIcon} className={classes.icon} alt="auth" />
          <Typography>AUTORYZACJA</Typography>
        </Animated>
      </Grid>
    </Grid>
  );
};

export default AuthScreen;
