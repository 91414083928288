import React from "react";

function useForm(initValues = {}) {
  const [values, setValues] = React.useState(initValues);

  const handleChange = (name) => (e) =>
    setValues({ ...values, [name]: e.target.value });
  const setFieldValue = (name, val) => setValues({ ...values, [name]: val });
  const setFieldValues = (val) => setValues({ ...values, ...val });
  const handleReset = () => setValues({});

  return { values, handleChange, handleReset, setFieldValue, setFieldValues };
}

export default useForm;

/*
 * How to use?
 * const {handleChange, values} = useForm({email: '', password: ''});
 *
 * 1st - (handleChange) function to set new parameter value
 * 2nd - (values) object that stores values set in hook init
 *
 * to work with form use onChange parameter to invoke handleChange
 *
 * */
