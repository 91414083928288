import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      height: "50px",
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.black,
      marginLeft: 4,
      marginRight: 4,
    },
    imageList: {
      width: "80%",
      height: "80%",
    },
  };
};

export default styles;
