import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { auth, users } from "../utils/api";
import { queryKeys } from "../constants/queryKeys";

export const useGetUsers = (page, limit, handleError) => {
  const { data, isFetching: isFetchingUsers } = useQuery({
    queryKey: [queryKeys.USERS, page],
    queryFn: () => users.getPage(page, limit),
    onSuccess: () => {},
    onError: (err) => {
      handleError && handleError(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingUsers,
    amount: data?.data?.beeGrowUserAmount,
    users: data?.data?.beeGrowUserResponseList,
  };
};

export const useGetOneUser = (id, handleError) => {
  const { data, isFetching: isFetchingUser } = useQuery({
    queryKey: [queryKeys.ONE_USER],
    queryFn: () => users.one(id),
    onSuccess: () => {},
    onError: (err) => {
      handleError && handleError(err);
    },
    retry: false,
  });
  return {
    isFetchingUser,
    user: data?.data,
  };
};

export const useGetCurrentUser = (handleError) => {
  const { data, isFetching: isFetchingUser } = useQuery({
    queryKey: [queryKeys.CURRENT_USER],
    queryFn: () => users.whoAmI(),
    onSuccess: () => {},
    onError: (err) => {
      handleError && handleError(err);
    },
    retry: false,
  });
  return {
    isFetchingUser,
    email: data?.data?.username,
  };
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const logout = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === queryKeys.CURRENT_USER,
    });
  };
  return { logout };
};

export const useBlockUser = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: blockUser, isLoading } = useMutation(users.block, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryKeys.USERS,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.ONE_USER],
      });
    },
    onError: () => {
      handleError && handleError();
    },
  });
  return {
    blockUser,
    isLoading,
  };
};

export const useUnblockUser = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: unblockUser, isLoading } = useMutation(users.unblock, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryKeys.USERS,
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.ONE_USER],
      });
    },
    onError: () => {
      handleError && handleError();
    },
  });
  return {
    unblockUser,
    isLoading,
  };
};

export const useAddUser = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: addUser, isLoading } = useMutation(auth.register, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === queryKeys.USERS,
      });
    },
    onError: () => {
      handleError && handleError();
    },
  });
  return {
    addUser,
    isLoading,
  };
};
