const styles = (theme) => {
  return {
    label: {
      width: "100%",
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    container: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      height: "100%",
      width: "100%",
    },
    loader: {
      "justify-content": "center",
      "align-items": "center",
    },
    loading: {
      "font-family": "Montserrat",
      "font-weight": "bold",
    },
  };
};

export default styles;
