import useStyles from "./UserInfo.styles";
import { Person } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

import colors from "../../constants/colors";
import { useGetCurrentUser } from "../../query/useUserQuery";

const UserInfo = () => {
  const classes = useStyles();
  const { email } = useGetCurrentUser();

  const PersonIcon = withStyles({
    root: {
      color: colors.white,
    },
  })(Person);

  return (
    <Grid item container spacing={1} direction="row">
      <Grid item xs={12}>
        <Box
          display="flex"
          height="100%"
          width="100%"
          alignItems="left"
          justifyContent="left"
          padding={2}
        >
          <PersonIcon />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: 15 }}>
        <Typography className={classes.primary}>{email}</Typography>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
