import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "./NavBar.styles";
import { MainListItems, logoutListItems } from "./NavigationItems";
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import UserInfo from "./UserInfo";
import { ArrowBackIos } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import IconSrc from "../../assets/images/icon.png";

import colors from "../../constants/colors";

function NavBar(props) {
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const WhiteDivider = withStyles({
    root: {
      backgroundColor: colors.white,
      marginTop: 20,
    },
  })(Divider);

  const drawer = (
    <Grid
      container
      justifyContent="center"
      alignContent="flex-start"
      className={classes.grid}
    >
      <Grid item xs={10} container>
        <Grid container align="center">
          <UserInfo />
          <Grid item className={classes.listItem}>
            <WhiteDivider />
            <MainListItems />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Hidden smUp>
          <ArrowBackIos
            className={classes.buttonBack}
            onClick={handleDrawerToggle}
          />
        </Hidden>
      </Grid>
    </Grid>
  );

  const logo = (
    <Box display="flex" alignItems="center">
      <img src={IconSrc} alt="icon" style={{ width: "64px", height: "64px" }} />
      <Typography variant="h6">BeeGrow</Typography>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {logo}
          <Box flexGrow="1" />
          {logoutListItems}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default NavBar;
