const styles = (theme) => {
  return {
    root: {
      width: "100%",
      minHeight: "100vh",
    },
    center: {
      padding: "10%",
    },
    icon: {
      marginBottom: "10%",
      width: "250px",
      height: "250px",
    },
    text: {
      "font-family": "Montserrat",
    },
  };
};

export default styles;
