import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./QRScreen.styles";
import QRTable from "../../components/QRTable/QRTable";
import NewQRcodeWindow from "../../components/QR/NewQRcodeWindow";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import { useGetQR } from "../../query/useQRQuery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(styles);

const QRScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = useState(
    localStorage.getItem("currentQRTablePage")
      ? Number(localStorage.getItem("currentQRTablePage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("currentQRTableRowsPerPage")
      ? Number(localStorage.getItem("currentQRTableRowsPerPage"))
      : 10
  );
  const [search, setSearch] = useState("");
  useEffect(() => {
    setSearch("");
  }, []);

  const handleError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_QR_CODES);
  };

  const handleAddError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_CREATE_QR_CODE);
  };

  const handleDeleteError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_QR_CODE);
  };

  const handleEditError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_QR_CODE);
  };

  const { qrs, amount, isFetchingQR } = useGetQR(
    page + 1,
    rowsPerPage,
    handleError
  );

  const handleChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {!isFetchingQR ? (
        <Grid container spacing={matches ? 3 : 0}>
          <ListHeader
            variant="title-add-search"
            title="Lista kodów QR"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj użytkownika"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <QRTable
                QRCodes={qrs?.filter((code) => {
                  if (search !== "")
                    return code?.userEmail?.toLowerCase().startsWith(search);
                  else return true;
                })}
                handleDeleteError={handleDeleteError}
                handleEditError={handleEditError}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={amount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <Typography className={classes.loading}>
            Trwa ładowanie kodów ...
          </Typography>
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <NewQRcodeWindow
        open={open}
        close={() => setOpen(false)}
        handleError={handleAddError}
      />
    </ThemeProvider>
  );
};

export default QRScreen;
