import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import styles from "./UserInfoList.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CommonModal from "../common/modals/CommonModal";
import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { useBlockUser, useUnblockUser } from "../../query/useUserQuery";

const useStyles = makeStyles(styles);

const UserList = ({ users, page, rowsPerPage }) => {
  const history = useHistory();
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [block, setBlock] = useState(false);
  const classes = useStyles(matches);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleBlockError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_BLOCK_USER);
  };

  const handleUnblockError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_UNBLOCK_USER);
  };

  const { blockUser } = useBlockUser(handleBlockError);
  const { unblockUser } = useUnblockUser(handleUnblockError);

  const handleBlock = (id, blocked, event) => {
    event.stopPropagation();
    setOpenConfirmation(true);
    setBlock(blocked);
    setId(id);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.email}>Email</TableCell>
                <TableCell className={classes.blocked}>Zablokowany</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.email}>Email</TableCell>
                <TableCell className={classes.blocked}>Zablokowany</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {users?.map((user, idx) => (
              <TableRow
                key={idx}
                className={classes.row}
                onClick={() => {
                  localStorage.setItem("currentUserInfoListPage", page);
                  localStorage.setItem(
                    "currentUserInfoListRowsPerPage",
                    rowsPerPage
                  );
                  history.push(`/dashboard/user/${user.id}`);
                }}
              >
                <TableCell>
                  <Typography className={classes.cell} component={"span"}>
                    {user.username}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12} align="center">
                      <Button
                        className={
                          user.blocked
                            ? classes.iconLabelGreen
                            : classes.iconLabelRed
                        }
                        size="large"
                        onClick={(e) => {
                          handleBlock(user.id, user.blocked, e);
                        }}
                        startIcon={
                          user.blocked ? (
                            <LockOpenIcon className={classes.unlock} />
                          ) : (
                            <BlockIcon className={classes.delete} />
                          )
                        }
                      >
                        {user.blocked ? "ODBLOKUJ" : "ZABLOKUJ"}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => (block ? unblockUser(id) : blockUser(id))}
        title={
          block
            ? COMMON_MODAL_TEXTS.USER_TITLE_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_TITLE_BLOCK
        }
        content={
          block
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_CONTENT_BLOCK
        }
      />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </>
  );
};

export default UserList;
