import { createTheme } from "@material-ui/core/styles";
const theme = createTheme({
  breakpoints: {
    value: {
      xs: 0,
      sm: 330,
      md: 400,
      lg: 450,
      xl: 700,
    },
  },
  palette: {
    primary: {
      main: "#67c324",
    },
    secondary: {
      main: "#de0038",
    },
  },
});

export default theme;
