import axios from "axios";
import tokenUtils from "./tokenUtils";

const API_URL = process.env.REACT_APP_API_URL;

const getHeaders = () => ({
  headers: {
    authorization: !!tokenUtils.getToken()
      ? `Bearer ${tokenUtils.getToken()}`
      : "",
  },
});

const getHeadersQR = () => ({
  headers: {
    authorization: !!tokenUtils.getToken()
      ? `Bearer ${tokenUtils.getToken()}`
      : "",
  },
  responseType: "arraybuffer",
});

class Request {
  delete = (url) => axios.delete(`${API_URL}${url}`, getHeaders());
  get = (url) => axios.get(`${API_URL}${url}`, getHeaders());
  getSpecial = (url) => axios.get(`${API_URL}${url}`, getHeadersQR());
  put = (url, body) => axios.put(`${API_URL}${url}`, body, getHeaders());
  post = (url, body) => axios.post(`${API_URL}${url}`, body, getHeaders());
  patch = (url, body) => axios.patch(`${API_URL}${url}`, body, getHeaders());
}

const requests = new Request();

export const auth = {
  login: (email, password) =>
    requests.post("/auth/login", { username: email, password }),
  register: (data) =>
    requests.post("/auth/register", {
      username: data.username,
      password: data.password,
    }),
  activate: (code) => requests.post("/auth/activate", { code }),
  resendActivate: (email) => requests.post("/auth/activate/resend", { email }),
  changePassword: (currentPassword, newPassword, newPasswordConfirmed) =>
    requests.put("/auth/password/change-password", {
      currentPassword,
      newPassword,
      newPasswordConfirmed,
    }),
  changeEmail: (currentEmail, newEmail) =>
    requests.put("/api/email", { currentEmail, newEmail }),
};

export const users = {
  whoAmI: () => requests.get("/api/user"),
  getPage: (page, limit) =>
    requests.get(`/admin/user/page/${page}/limit/${limit}`),
  one: (id) => requests.get(`/admin/user/${id}`),
  block: (id) => requests.patch(`/admin/user/block/${id}`),
  unblock: (id) => requests.patch(`/admin/user/unblock/${id}`),
};

export const cultivations = {
  all: () => requests.get("/api/cultivation-type"),
  one: (id) => requests.get(`/api/cultivation-type/${id}`),
  names: () => requests.get("/api/cultivation-type/name"),
  oneName: (cultivationTypeName) =>
    requests.get(`/api/cultivation-type/name/${cultivationTypeName}`),
  add: (payload) => requests.post("/admin/cultivation-type", payload),
  edit: (data) =>
    requests.put(`/admin/cultivation-type/${data.id}`, data.payload),
  delete: (id) => requests.delete(`/admin/cultivation-type/${id}`),
};

export const push = {
  send: (payload) => requests.post("/admin/push", payload),
};

export const field = {
  all: () => requests.get("/api/field"),
  one: (fieldId) => requests.get(`/api/field/${fieldId}`),
  coordinates: (fieldId) => requests.get(`/api/field/coordinates/${fieldId}`),
  add: (payload) => requests.post("/api/field", payload),
  edit: (fieldId, description, name) =>
    requests.put(`/api/field/${fieldId}`, { description, name }),
  delete: (fieldId) => requests.delete(`/api/field/${fieldId}`),
};

export const qrCodes = {
  getPage: (page, limit) =>
    requests.get(`/admin/qrcode/page/${page}/limit/${limit}`),
  one: (qrCodeId) => requests.get(`/admin/qrcode/${qrCodeId}`),
  add: () => requests.post("/admin/qrcode"),
  edit: (data) =>
    requests.patch(`/admin/qrcode`, {
      beeGrowUserId: data.beeGrowUserId,
      uuid: data.uuid,
    }),
  delete: (qrCodeId) => requests.delete(`/admin/qrcode/${qrCodeId}`),
};
