import React, { useState, useEffect } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { styles } from "./CultivationArticlesTable.styles";
import { theme } from "./Cultivation.theme";
import AddCultivationTypeWindow from "../../components/ModalWindows/CultivationType/AddCultivationTypeWindow";
import EditCultivatonTypeWindow from "../../components/ModalWindows/CultivationType/EditCultivationTypeWindow";
import { Hidden } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import SuccessSnackBar from "../../components/Snackbar/SuccessSnackBar";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import CommonModal from "../../components/common/modals/CommonModal";
import DeleteEditButton from "../../components/common/buttons/DeleteEditButton";
import ListHeader from "../../components/common/ListHeader";

import {
  COMMON_MODAL_TEXTS,
  HARVEST_MESSAGES,
} from "../../constants/dictionary";
import {
  useAddCultivation,
  useDeleteCultivation,
  useEditCultivation,
  useGetCultivations,
} from "../../query/useCultivationQuery";

const useStyles = makeStyles(styles);

const TypesTable = () => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [current, setCurrent] = useState({});
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isExpanded, setIsExpanded] = useState([]);

  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleOpen = () => setOpen(!open);
  const toggleOpenEdit = () => setOpenEdit(!openEdit);
  const toggleOpenDelete = () => setOpenDelete(!openDelete);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const handleSuccess = (text) => {
    setSuccess(true);
    setSuccessText(text);
  };

  const { cultivations, isFetchingCultivations } =
    useGetCultivations(handleError);
  const { addCultivation } = useAddCultivation(handleError, handleSuccess);
  const { editCultivation } = useEditCultivation(handleError, handleSuccess);
  const { deleteCultivation } = useDeleteCultivation(
    handleError,
    handleSuccess
  );

  const handleDelete = () => deleteCultivation(current.id);

  const getAreaString = (lower, upper) => {
    if ((lower === null || lower === 0) && (upper === null || upper === 0))
      return "-";
    if ((lower && upper === null) || upper === 2147483647)
      return `powyżej ${lower}`;
    if ((lower === null || lower === 0) && upper) return `do ${upper}`;
    return `${lower} - ${upper}`;
  };

  const expand = (index) => {
    let isExpandedCopy = [...isExpanded];
    isExpandedCopy[index] = !isExpandedCopy[index];

    setIsExpanded(isExpandedCopy);
  };

  useEffect(() => {
    cultivations.forEach(() => setIsExpanded((prev) => [...prev, false]));
  }, [cultivations]);

  return (
    <ThemeProvider theme={theme}>
      {!isFetchingCultivations ? (
        <TableContainer component={Paper}>
          <ListHeader
            variant="title-add"
            title="Typy upraw"
            handleAdd={toggleOpen}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.value}>
                  <Typography>Nazwa uprawy</Typography>
                </TableCell>
                <TableCell className={classes.value} />
                <TableCell className={classes.value} />
                <TableCell className={classes.value} />
                <TableCell className={classes.value} />
                <TableCell className={classes.value} />
              </TableRow>
            </TableHead>

            <TableBody>
              {cultivations
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <TableRow>
                      <TableCell align="left">
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          sx={{
                            [theme.breakpoints.down("sm")]: {
                              justifyContent: "center",
                            },
                          }}
                        >
                          <Grid
                            item
                            xs={matches ? 12 : 8}
                            align={matches ? "center" : "left"}
                            className={matches ? classes.breakline : ""}
                          >
                            <Typography
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                wordBreak: "break-word",
                                width: "40vw",
                                maxWidth: "40vw",
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          {matches && (
                            <DeleteEditButton
                              mobile={true}
                              editClickHandler={(e) => {
                                e.stopPropagation();
                                setCurrent(item);
                                toggleOpenEdit();
                              }}
                              deleteClickHandler={(e) => {
                                e.stopPropagation();
                                setCurrent(item);
                                toggleOpenDelete();
                              }}
                            />
                          )}
                        </Grid>
                      </TableCell>

                      <TableCell className={classes.cell} />
                      <TableCell className={classes.cell} />
                      <TableCell className={classes.cell} />
                      <Hidden mdUp>
                        <TableCell className={classes.cell} />
                      </Hidden>

                      {!matches && (
                        <DeleteEditButton
                          mobile={false}
                          editClickHandler={(e) => {
                            e.stopPropagation();
                            setCurrent(item);
                            toggleOpenEdit();
                          }}
                          deleteClickHandler={(e) => {
                            e.stopPropagation();
                            setCurrent(item);
                            toggleOpenDelete();
                          }}
                        />
                      )}

                      <TableCell>
                        {isExpanded[index] ? (
                          <ArrowUpwardIcon
                            onClick={() => expand(index)}
                            color={"secondary"}
                            style={{
                              border: "2px solid black",
                              borderRadius: "15px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            onClick={() => expand(index)}
                            color={"primary"}
                            style={{
                              border: "2px solid black",
                              borderRadius: "15px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>

                    {isExpanded[index] && (
                      <TableRow>
                        <TableCell className={classes.value}>
                          {"Zalecana ilość uli"}
                        </TableCell>
                        <TableCell className={classes.value}>
                          {"Ilość drzew"}
                        </TableCell>
                        <TableCell className={classes.value}>
                          {"Typ zbioru"}
                        </TableCell>

                        <TableCell className={classes.value} />
                        <TableCell className={classes.value} />
                        <TableCell className={classes.value} />
                      </TableRow>
                    )}

                    {isExpanded[index] &&
                      item?.hiveConfigList.map((config, idx) => (
                        <TableRow key={`${index}${idx}`}>
                          <TableCell className={classes.cell}>
                            {config.suggestedHivesCount}
                          </TableCell>

                          <TableCell className={classes.cell}>
                            {getAreaString(
                              config.lowerTreeDensityRate,
                              config.upperTreeDensityRate
                            )}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {HARVEST_MESSAGES[config.isHarvestManual]}
                          </TableCell>
                          <TableCell className={classes.cell} />
                          <TableCell className={classes.cell} />
                          <TableCell className={classes.cell} />
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50]}
            component="div"
            count={cultivations?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
            classes={{
              toolbar: classes.toolbar,
              caption: classes.caption,
            }}
            labelRowsPerPage={"Ilość wierszy na stronę"}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} z ${
                count !== -1 ? count : `więcej niż ${to}`
              }`;
            }}
          />
        </TableContainer>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <SuccessSnackBar
        open={success}
        text={successText}
        close={() => setSuccess(false)}
      />
      <AddCultivationTypeWindow
        handleAdd={addCultivation}
        open={open}
        toggleOpen={toggleOpen}
        handleError={handleError}
      />
      <CommonModal
        open={openDelete}
        toggleOpen={toggleOpenDelete}
        handleConfirmation={handleDelete}
        title={COMMON_MODAL_TEXTS.FIELD_TYPE_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
      <EditCultivatonTypeWindow
        handleEdit={editCultivation}
        open={openEdit}
        toggleOpen={toggleOpenEdit}
        handleError={handleError}
        current={current}
      />
    </ThemeProvider>
  );
};

export default TypesTable;
