import { useEffect } from "react";
import styles from "./Dashboard.styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Toolbar } from "@material-ui/core";
import NavBar from "../../components/Dashboard/NavBar";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import manageLocalStorage from "../../utils/manageLocalStorage";

import TypesTable from "../Cultivation/TypesTable";
import UserInfoScreen from "../UserInfoScreen/UserInfoScreen";
import OneUserInfoScreen from "../OneUserInfoScreen.js/OneUserInfoScreen";
import PushNotifications from "../PushNotifications/PushNotifications";
import QRScreen from "../QR/QRScreen";
import QRInfoScreen from "../QRInfoScreen/QRInfoScreen";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    manageLocalStorage(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <div className={classes.content}>
        <Toolbar />
        <div className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <Switch>
                <Route exact path="/dashboard/cultivationtypes">
                  <TypesTable />
                </Route>
                <Route exact path="/dashboard/users">
                  <UserInfoScreen />
                </Route>
                <Route exact path="/dashboard/user/:id">
                  <OneUserInfoScreen />
                </Route>
                <Route exact path="/dashboard/QR">
                  <QRScreen />
                </Route>
                <Route exact path="/dashboard/QR/:id">
                  <QRInfoScreen />
                </Route>
                <Route exact path="/dashboard/push">
                  <PushNotifications />
                </Route>
                <Route>
                  <Redirect to="/dashboard/cultivationtypes" />
                </Route>
              </Switch>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
