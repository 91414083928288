import { Grid, Typography } from "@mui/material";
import { Clear as NoHiveIcon } from "@mui/icons-material";
import React from "react";

export default function NoHive({ center }) {
  return (
    <Grid
      container
      justifyContent={center ? "center" : "left"}
      style={{ marginLeft: -5 }}
    >
      <NoHiveIcon style={{ marginRight: 5, color: "red" }} />
      <Typography style={{ color: "black", fontWeight: 600 }}>Nie</Typography>
    </Grid>
  );
}
