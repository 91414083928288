import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export const SortIcon = ({ isactive, asacdesc }, props) => {
	return asacdesc ? (
		<ArrowUpwardIcon
			color={isactive ? "primary" : "secondary"}
			{...props}
		/>
	) : (
		<ArrowDownwardIcon
			color={isactive ? "primary" : "secondary"}
			{...props}
		/>
	);
};