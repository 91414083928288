import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom";
import { ThemeProvider, makeStyles } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";

import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import styles from "./OneUserInfoScreen.styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserInfoBox from "../../components/UserInfo/UserInfoBox";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import { useGetOneUser } from "../../query/useUserQuery";

const useStyles = makeStyles(styles);

const OneUserInfoScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_USER);
  };

  const { isFetchingUser, user } = useGetOneUser(id, handleError);

  return (
    <ThemeProvider theme={theme}>
      {!isFetchingUser ? (
        <UserInfoBox info={user} handleError={handleError} />
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default OneUserInfoScreen;
