import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "min(80%, 90%)",
    height: "auto",
    maxHeight: "70%",
    overflowX: "hidden",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    "border-radius": "10px",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  error: {
    color: colors.htmlRed,
    align: "center",
    "font-weight": "bold",
    "font-family": "Montserrat",
  },
  text: {
    "font-family": "Montserrat",
    "font-weight": "bold",
  },
  area: {
    width: "70%",
  },
  buttonBox: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "35%",
      marginRight: "35%",
    },
  },
  rootList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
  },
  rootElements: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& *": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100% !important",
      },
    },
  },
  listText: {
    "font-weight": "bold",
    "font-family": "Montserrat",
  },
  delete: {
    color: colors.htmlRed,
    cursor: "pointer",
  },
  label: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
  },
}));

export default useStyles;
