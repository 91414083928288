import { makeStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "min(70%, 90%)",
    maxHeight: "90%",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    "border-radius": "10px",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  error: {
    color: colors.htmlRed,
    align: "center",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  area: {
    width: "70%",
  },
}));

export default useStyles;
