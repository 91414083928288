const colors = {
  black: "#000000",
  carmine: "#DE0038",
  carmineLight: "#DE0043",
  carmineDark: "#DE0020",
  white: "#FFFFFF",
  platinum: "#E8E8E8",
  quickSilver: "#A7A7A7",
  green: "#67C324",
  greenLight: "#67CC24",
  htmlBlue: "#0000FF",
  melon: "#FBABA7",
  htmlGray: "#808080",
  htmlOrange: "#FFA500",
  htmlGreen: "#008000",
  htmlRed: "#FF0000",
  brightLavender: "#C38CED",
  skyBlue: "#64D8F5",
  eerieBlack: "#1F1F1F",
  spanishGray: "9A9A9A",
  oxfordBlue: "#022C43",
  lightGray: "#D3D3D3",
  transparent: "#00FFFFFF",
  lotion: "#FAFAFA",
  androidGreen: "#B1CC3F",
};

export default colors;
