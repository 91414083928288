import background from "../../assets/images/background.jpg";

import colors from "../../constants/colors";

const styles = {
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "1em",
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  paperContainer: {
    display: "flex",
    flexGrow: 1,
    height: "90%",
  },
  paper: {
    backgroundColor: colors.white,
    height: "100%",
    width: "100%",
    borderRadius: 20,
    padding: 20,
    overflow: "hidden",
  },
  paperContent: {
    overflowWrap: "auto",
    overflowY: "auto",
    height: "100%",
  },
};

export default styles;
