import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cultivations } from "../utils/api";
import { queryKeys } from "../constants/queryKeys";
import {
  SERVER_ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../constants/dictionary";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const useGetCultivations = (handleError) => {
  const history = useHistory();
  const { data, isFetching: isFetchingCultivations } = useQuery({
    queryKey: [queryKeys.CULTIVATION_TYPES],
    queryFn: () => cultivations.all(),
    onSuccess: () => {},
    onError: (err) => {
      if (err.response.status === 403) {
        handleError(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
        setTimeout(() => {
          history.push("/logout");
        }, 2000);
      } else handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_CROPS);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingCultivations,
    cultivations: data?.data ? data?.data?.sort((a, b) => b.id - a.id) : [],
  };
};

export const useGetOneCultivation = (id, handleError) => {
  const { data, isFetching: isFetchingCultivation } = useQuery({
    queryKey: [queryKeys.ONE_CULTIVATION],
    queryFn: () => cultivations.one(id),
    onSuccess: () => {},
    onError: (err) => {
      handleError(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingCultivation,
    cultivation: data?.data,
  };
};

export const useGetCultivationNames = (handleError) => {
  const { data, isFetching: isFetchingNames } = useQuery({
    queryKey: [queryKeys.CULTIVATION_NAMES],
    queryFn: () => cultivations.names(),
    onSuccess: () => {},
    onError: (err) => {
      handleError(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingNames,
    names: data?.data,
  };
};

export const useAddCultivation = (handleError, handleSuccess, successText) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutateAsync: addCultivation, isLoading: isAddUpdatingCultivation } =
    useMutation(cultivations.add, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.CULTIVATION_TYPES],
        });
        handleSuccess(SUCCESS_MESSAGES.SUCCESS_ADD_CROP);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          handleError(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
          setTimeout(() => {
            history.push("/logout");
          }, 2000);
        } else
          switch (err.response.data) {
            case "Cultivation type with this name already exist!":
              handleError(SERVER_ERROR_MESSAGES.CROP_EXISTS);
              break;
            default:
              handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_CROP);
              break;
          }
      },
    });
  return {
    addCultivation,
    isAddUpdatingCultivation,
  };
};

export const useEditCultivation = (handleError, handleSuccess) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutateAsync: editCultivation, isLoading: isEditUpdatingCultivation } =
    useMutation(cultivations.edit, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.CULTIVATION_TYPES],
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.ONE_CULTIVATION],
        });
        handleSuccess(SUCCESS_MESSAGES.SUCCESS_EDIT_CROP);
      },
      onError: (err) => {
        if (err.response.status === 403) {
          handleError(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
          setTimeout(() => {
            history.push("/logout");
          }, 2000);
        } else handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_CROP);
      },
    });
  return {
    editCultivation,
    isEditUpdatingCultivation,
  };
};

export const useDeleteCultivation = (handleError, handleSuccess) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const {
    mutateAsync: deleteCultivation,
    isLoading: isDeleteUpdatingCultivation,
  } = useMutation(cultivations.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.CULTIVATION_TYPES],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.ONE_CULTIVATION],
      });
      handleSuccess(SUCCESS_MESSAGES.SUCCESS_DELETE_CROP);
    },
    onError: (err) => {
      if (err.response.status === 403) {
        handleError(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
        setTimeout(() => {
          history.push("/logout");
        }, 2000);
      } else handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_CROP);
    },
  });
  return {
    deleteCultivation,
    isDeleteUpdatingCultivation,
  };
};
