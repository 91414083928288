import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  error: {
    color: colors.htmlRed,
    fontWeight: "bolder",
  },
  userSelect: {
    width: 240,
    height: 40,
    marginLeft: 12,
  },
  cultivationSelect: {
    width: 240,
    height: 40,
    marginLeft: 10,
  },
  margin: {
    marginTop: 10,
  },
}));

export default useStyles;
