class TokenUtils {
  getToken = () => {
    const tokenItem = localStorage.getItem("tokenData");
    return tokenItem;
  };

  setToken = (data) => {
    localStorage.setItem("tokenData", data);
  };

  clearToken = () => {
    localStorage.removeItem("tokenData");
  };
}

const tokenUtils = new TokenUtils();

export default tokenUtils;
