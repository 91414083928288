import React from "react";

import CommonModal from "../common/modals/CommonModal";

import { MODAL_TYPE, COMMON_MODAL_TEXTS } from "../../constants/dictionary";
import { useAddQR } from "../../query/useQRQuery";

const NewQRcodeWindow = ({ open, close, handleError }) => {
  const { addQR } = useAddQR(handleError);
  const createCode = () => {
    addQR();
    close();
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={close}
      handleConfirmation={createCode}
      title={COMMON_MODAL_TEXTS.ADD_QR_CODE_TITLE}
      content={COMMON_MODAL_TEXTS.ADD_QR_CODE_CONTENT}
      type={MODAL_TYPE.ADD}
    />
  );
};

export default NewQRcodeWindow;
