import { Box, Typography } from "@mui/material";
import { Animated } from "react-animated-css";
import colors from "../../constants/colors";

export default function ErrorBox({ text, id }) {
  return (
    <Animated animationIn="tada" isVisible={true} animationInDuration={1000}>
      <Box
        sx={{
          background: colors.carmine,
          width: "60%",
          borderRadius: 6,
          padding: 1,
          mb: 5,
        }}
      >
        <Typography sx={{ color: colors.white }}>{text}</Typography>
      </Box>
    </Animated>
  );
}
