import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  Avatar,
  useMediaQuery,
} from "@material-ui/core";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import BlockIcon from "@material-ui/icons/Block";

import styles from "./UserInfoBox.styles";
import theme from "../../screens/Login/LoginSreen.breakpoints";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CommonModal from "../common/modals/CommonModal";
import CustomButton from "../common/buttons/CustomButton";
import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { useBlockUser, useUnblockUser } from "../../query/useUserQuery";

const useStyles = makeStyles(styles);

const UserInfoBox = ({ info }) => {
  const history = useHistory();
  const classes = useStyles(styles);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleBlockError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_BLOCK_USER);
  };

  const handleUnblockError = (err) => {
    setError(true);
    if (err.response?.status === 403) {
      setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
      setTimeout(() => {
        history.push("/logout");
      }, 2000);
    } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_UNBLOCK_USER);
  };

  const { blockUser } = useBlockUser(handleBlockError);
  const { unblockUser } = useUnblockUser(handleUnblockError);

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} direction="row">
        <Grid container item xs={matches ? 12 : 7} justifyContent="center">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
            className={classes.rootGrid}
          >
            <Grid item align="center">
              <Avatar className={classes.avatar}>
                <AccountBoxRoundedIcon className={classes.icon} />
              </Avatar>
            </Grid>
            <Grid container item align="center">
              <Grid item xs={12}>
                <Typography className={classes.headerText}>Email</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subtext}>
                  {info?.username}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box className={!matches ? classes.RightBox : ""}>
                <Grid item xs={12} align="center">
                  <Box className={classes.margin}>
                    <Typography className={classes.active}>
                      Użytkownik zweryfikowany
                    </Typography>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={
                          info?.activated
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={info?.activated ? "Tak" : "Nie"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
              <Grid item xs={12} align="center">
                <Button
                  className={
                    info?.blocked
                      ? classes.iconLabelGreen
                      : classes.iconLabelRed
                  }
                  size="large"
                  onClick={() => setOpenConfirmation(true)}
                  startIcon={
                    info?.blocked ? (
                      <LockOpenIcon className={classes.buttonIcon} />
                    ) : (
                      <BlockIcon className={classes.buttonIcon} />
                    )
                  }
                >
                  {info?.blocked ? "ODBLOKUJ" : "ZABLOKUJ"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() =>
          info?.blocked ? unblockUser(info?.id) : blockUser(info?.id)
        }
        title={
          info?.blocked
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_TITLE_BLOCK
        }
        content={
          info?.blocked
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_CONTENT_BLOCK
        }
      />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </Box>
  );
};

export default UserInfoBox;
