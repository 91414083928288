export const sortConstants = {
  qrs: "qrsSort",
};

export const qrsSort = {
  nameA: "nameAsc",
  nameD: "nameDesc",
  dateA: "dateAsc",
  dateD: "dateDesc",
};

export const searchConstants = {
  users: "usersSearch",
};
