import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  useMediaQuery,
} from "@material-ui/core";

import styles from "./QRTable.styles";
import EditQRcodeWindow from "../QR/EditQRcodeWindow";
import { SortIcon } from "../common/buttons/SortIcon";
import CommonModal from "../common/modals/CommonModal";
import DeleteEditButton from "../common/buttons/DeleteEditButton";
import fields from "../../constants/QRcodesConstants";
import { COMMON_MODAL_TEXTS } from "../../constants/dictionary";
import { sortConstants, qrsSort } from "../../constants/localStorage";
import { useDeleteQR } from "../../query/useQRQuery";
import NoHive from "../QR/NoHive";
import Hive from "../QR/Hive";

const useStyles = makeStyles(styles);

const QRTable = ({
  QRCodes,
  handleDeleteError,
  handleEditError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [isActiveName, setIsActiveName] = useState(false);
  const [isAscN, setIsAscN] = useState(null);
  const [isAscDate, setIsAscDate] = useState(null);
  const [isActiveDate, setIsActiveDate] = useState(false);

  const [sorted, setSorted] = useState();
  const { deleteQR } = useDeleteQR(handleDeleteError);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (code, e) => {
    e.stopPropagation();
    setInfoForEdit(code);
    setOpenEdit(true);
  };

  const sortDate = (isAsc = null) => {
    setIsAscDate((prev) => isAsc ?? !prev);
    setIsActiveName(false);
    setIsActiveDate(true);
  };

  const sortName = (isAsc = null) => {
    setIsActiveDate(false);
    setIsActiveName(true);
    setIsAscN((prev) => isAsc ?? !prev);
  };

  const deleteCode = (qrId) => {
    deleteQR(qrId);
  };

  useEffect(() => {
    if (QRCodes?.length) {
      const sortToApply = localStorage.getItem(sortConstants.qrs);

      switch (sortToApply) {
        case qrsSort.nameA:
          sortName(true);
          break;
        case qrsSort.nameD:
          sortName(false);
          break;
        case qrsSort.dateA:
          sortDate(true);
          break;
        case qrsSort.dateD:
          sortDate(false);
          break;
        default:
          sortDate(false);
          break;
      }
    }
  }, [QRCodes]);

  useEffect(() => {
    if (isAscDate !== null && QRCodes?.length) {
      const sorted = [...QRCodes].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return isAscDate ? dateA - dateB : dateB - dateA;
      });

      setSorted(sorted);

      if (isAscDate) {
        localStorage.setItem(sortConstants.qrs, qrsSort.dateA);
      } else {
        localStorage.setItem(sortConstants.qrs, qrsSort.dateD);
      }
    }
  }, [isAscDate, QRCodes]);

  useEffect(() => {
    if (isAscN !== null && QRCodes?.length) {
      const sorted = [...QRCodes].sort((a, b) => {
        const nameA = a.hivePlaced ? fields[0].toLowerCase() : "Z";
        const nameB = b.hivePlaced ? fields[0].toLowerCase() : "Z";
        if (isAscN) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setSorted(sorted);

      if (isAscN) {
        localStorage.setItem(sortConstants.qrs, qrsSort.nameA);
      } else {
        localStorage.setItem(sortConstants.qrs, qrsSort.nameD);
      }
    }
  }, [isAscN, QRCodes]);

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.picRes} />
                <TableCell className={classes.codeRes} align="right" />
                <TableCell className={classes.dateRes} align="center">
                  <Button
                    onClick={() => sortDate()}
                    className={classes.button}
                    endIcon={
                      <SortIcon isactive={isActiveDate} asacdesc={isAscDate} />
                    }
                  >
                    <Typography className={classes.cell}>
                      DATA UTWORZENIA
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.connectedToRes} align="center">
                  <Button
                    onClick={() => sortName()}
                    className={classes.button}
                    endIcon={
                      <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                    }
                  >
                    PRZYPISANY
                  </Button>
                </TableCell>
                <TableCell className={classes.usersRes}>UŻYTKOWNIK</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center" className={classes.picture} />
                <TableCell align="left" className={classes.code}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.cell}>KOD</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center" className={classes.date}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Button
                        onClick={() => sortDate()}
                        className={classes.button}
                        endIcon={
                          <SortIcon
                            isactive={isActiveDate}
                            asacdesc={isAscDate}
                          />
                        }
                      >
                        <Typography className={classes.cellCenter}>
                          DATA UTWORZENIA
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center" className={classes.connectedTo}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Button
                        onClick={() => sortName()}
                        className={classes.button}
                        endIcon={
                          <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                        }
                      >
                        <Typography className={classes.cellCenter}>
                          PRZYPISANY
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.users}>UŻYTKOWNIK</TableCell>
                <TableCell className={classes.buttons} />
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {sorted?.map((code) => (
              <TableRow
                key={code && code.uuid}
                className={classes.row}
                onClick={() => {
                  localStorage.setItem("currentQRTablePage", page);
                  localStorage.setItem(
                    "currentQRTableRowsPerPage",
                    rowsPerPage
                  );
                  history.push(`/dashboard/QR/${code.id}`);
                }}
              >
                <TableCell align="center" component="th" scope="row">
                  <QRCode
                    value={code.uuid}
                    style={{
                      height: "auto",
                      width: "80%",
                      maxWidth: 100,
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={matches ? 12 : 8}
                      align={matches ? "center" : "left"}
                      className={matches ? classes.breakline : ""}
                    >
                      <Typography className={classes.cell}>
                        {code && code.uuid}
                      </Typography>
                    </Grid>
                    {matches && (
                      <DeleteEditButton
                        mobile={true}
                        editClickHandler={(e) => {
                          handleEdit(code, e);
                        }}
                        deleteClickHandler={(e) => {
                          handleDelete(code.id, e);
                        }}
                      />
                    )}
                  </Grid>
                </TableCell>

                <TableCell className={classes.cellCenter} align="left">
                  {code.createdAt
                    ? format(new Date(code.createdAt), "dd/MM/yyyy HH:mm")
                    : "Brak"}
                </TableCell>

                {!matches && (
                  <TableCell className={classes.cellCenter} align="center">
                    {code.hivePlaced ? <Hive center /> : <NoHive center />}
                  </TableCell>
                )}
                {matches ? (
                  <TableCell className={classes.cellCenter} align="center">
                    {code.hivePlaced ? <Hive center /> : <NoHive center />}
                  </TableCell>
                ) : (
                  <TableCell>
                    <Typography
                      className={
                        code && code.userEmail ? classes.type : classes.disable
                      }
                    >
                      {code && code.userEmail ? code.userEmail : "Brak"}
                    </Typography>
                  </TableCell>
                )}
                {matches ? (
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={12} align="center">
                        <Typography
                          className={
                            code && code.userEmail
                              ? classes.type
                              : classes.disable
                          }
                        >
                          {code && code.userEmail ? code.userEmail : "Brak"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                ) : (
                  <DeleteEditButton
                    mobile={false}
                    editClickHandler={(e) => {
                      handleEdit(code, e);
                    }}
                    deleteClickHandler={(e) => {
                      handleDelete(code.id, e);
                    }}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteCode(id)}
        title={COMMON_MODAL_TEXTS.QR_TITLE}
        content={COMMON_MODAL_TEXTS.QR_CONTENT}
      />
      <EditQRcodeWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleEditError}
      />
    </>
  );
};

export default QRTable;
