import React, { useState, useEffect, useCallback } from "react";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import {
  Hidden,
  Switch,
  FormControlLabel,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import CommonModal from "../../common/modals/CommonModal";
import CustomButton from "../../common/buttons/CustomButton";

import useForm from "../../../hooks/useForm";
import useStyles from "../Modal.styles";
import { validateConfig } from "../../../utils/validation";
import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
  MODAL_TYPE,
  CULTIVATION_TYPE_ERRORS,
} from "../../../constants/dictionary";
import { HARVEST_MESSAGES } from "../../../constants/dictionary";

const AddCultivationTypeWindow = ({ handleAdd, open, toggleOpen }) => {
  const classes = useStyles();
  const { handleChange, values, setFieldValues } = useForm({
    name: "",
    suggestedHivesCount: "",
    lowerDensityRate: "",
    upperDensityRate: "",
  });
  const [isHarvestManual, setIsHarvestManual] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [errorLower, setErrorLower] = useState(false);
  const [errorUpper, setErrorUpper] = useState(false);
  const [errorHives, setErrorHives] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [noConfigsError, setNoConfigsError] = useState(false);
  const [incorrectRange, setIncorrectRange] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [configRangeError, setConfigRangeErorr] = useState(false);
  const [configRangeErrorMessage, setConfigRangeErrorMessage] = useState("");

  useEffect(() => {
    setErrorHives(false);
    setErrorLower(false);
    setErrorUpper(false);
    setErrorName(false);
    setNoConfigsError(false);
    setConfigs([]);
    setIsHarvestManual(true);
    setIncorrectRange(false);
    setConfigRangeErorr(false);
    setFormComplete(false);
    setFieldValues({
      name: "",
      suggestedHivesCount: "",
      lowerDensityRate: "",
      upperDensityRate: "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleHarvestingSwitch = (event) => {
    if (event.target.name === "manual" && event.target.checked)
      setIsHarvestManual(true);
    else if (event.target.name === "manual" && !event.target.checked)
      setIsHarvestManual(false);
    if (event.target.name === "combine" && event.target.checked)
      setIsHarvestManual(false);
    else if (event.target.name === "combine" && !event.target.checked)
      setIsHarvestManual(true);
  };

  const addConfig = () => {
    if (handleConfigRangeErrors()) return;
    const error = validateConfig(
      values.name,
      values.suggestedHivesCount,
      values.lowerDensityRate,
      values.upperDensityRate,
      setErrorName,
      setErrorHives,
      setErrorLower,
      setErrorUpper
    );

    if (!error) {
      const config = {
        isHarvestManual: isHarvestManual,
        lowerTreeDensityRate: Number(values.lowerDensityRate),
        suggestedHivesCount: Number(values.suggestedHivesCount),
        upperTreeDensityRate:
          Number(values.upperDensityRate) === 0
            ? null
            : Number(values.upperDensityRate),
      };

      setConfigs((prev) => [...prev, config]);
      setFieldValues({
        suggestedHivesCount: "",
        lowerDensityRate: "",
        upperDensityRate: "",
      });
      setNoConfigsError(false);
    }
  };

  useEffect(() => {
    if (values.name !== "" && configs.length) setFormComplete(true);
    else setFormComplete(false);
    if (values.upperDensityRate !== "" && values.lowerDensityRate !== "") {
      if (Number(values.upperDensityRate) > Number(values.lowerDensityRate)) {
        setIncorrectRange(false);
      } else {
        setIncorrectRange(true);
      }
    }
    if (values.upperDensityRate === "") setIncorrectRange(false);
  }, [values, configs]);

  const getMaxValues = useCallback(() => {
    let configToCheck;
    if (isHarvestManual) {
      configToCheck = configs.filter((e) => e.isHarvestManual === true);
    } else {
      configToCheck = configs.filter((e) => e.isHarvestManual === false);
    }
    let max = 0;
    for (let i = 0; i < configToCheck.length; ++i) {
      if (configToCheck[i].upperTreeDensityRate === null) {
        max = null;
        break;
      }
      if (configToCheck[i].upperTreeDensityRate > max)
        max = configToCheck[i].upperTreeDensityRate;
    }
    return max;
  }, [configs, isHarvestManual]);

  const handleConfigRangeErrors = useCallback(() => {
    const max = getMaxValues();
    if (configs?.length === 0 && values.lowerDensityRate > 0) {
      setConfigRangeErorr(true);
      setConfigRangeErrorMessage(CULTIVATION_TYPE_ERRORS.INITIAL_RANGE);
      return true;
    }
    if (isHarvestManual) {
      if (max === null) {
        setConfigRangeErorr(true);
        setConfigRangeErrorMessage(CULTIVATION_TYPE_ERRORS.MANUAL_ENDED);
        return true;
      }
      if (values.lowerDensityRate <= max && max !== 0) {
        setConfigRangeErorr(true);
        setConfigRangeErrorMessage(
          CULTIVATION_TYPE_ERRORS.MANUAL_INCORRECT_RANGE
        );
        return true;
      }
      setConfigRangeErorr(false);
      return false;
    } else {
      if (max === null) {
        setConfigRangeErorr(true);
        setConfigRangeErrorMessage(CULTIVATION_TYPE_ERRORS.COMBINE_ENDED);
        return true;
      }
      if (values.lowerDensityRate <= max && max !== 0) {
        setConfigRangeErorr(true);
        setConfigRangeErrorMessage(
          CULTIVATION_TYPE_ERRORS.COMBINE_INCORRECT_RANGE
        );
        return true;
      }
      setConfigRangeErorr(false);
      return false;
    }
  }, [values.lowerDensityRate, isHarvestManual, getMaxValues]);

  return (
    <CommonModal
      open={open}
      disabled={!formComplete}
      toggleOpen={toggleOpen}
      handleConfirmation={() => {
        if (configs.length > 0) {
          handleAdd({
            hiveConfigRequestList: configs.map((conf, idx) => {
              return {
                ...conf,
                upperTreeDensityRate:
                  idx === configs.length - 1 ? null : conf.upperTreeDensityRate,
              };
            }),
            name: values.name,
          });
          toggleOpen();
        } else {
          setNoConfigsError(true);
        }
      }}
      title={COMMON_MODAL_TEXTS.ADD_CULTIVATION_TYPE_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <TextField
          label="Wpisz nazwę typu"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="Nowy typ"
          variant="outlined"
          fullWidth
          required
          value={values.name}
          onChange={handleChange("name")}
          error={errorName}
        />
      </Grid>

      {configs.length > 0 && (
        <Grid item xs={12} align="center" style={{ marginBottom: "2%" }}>
          <Typography className={classes.listText}>
            Lista konfiguracji
          </Typography>
        </Grid>
      )}

      <Grid container>
        {configs.map((config, idx) => (
          <List className={classes.rootList} key={`List${idx}`}>
            <ListItem className={classes.rootElements}>
              <ListItemText
                primary="Typ zbiorów"
                style={{ width: "15%" }}
                secondary={
                  <Typography className={classes.listText}>
                    {HARVEST_MESSAGES[config.isHarvestManual]}
                  </Typography>
                }
              />
              <ListItemText
                primary="Zalecana ilość uli"
                secondary={
                  <Typography className={classes.listText}>
                    {config.suggestedHivesCount}
                  </Typography>
                }
              />
              <ListItemText
                primary="Dolna granica"
                secondary={
                  <Typography className={classes.listText}>
                    {config.lowerTreeDensityRate > 0
                      ? config.lowerTreeDensityRate
                      : "Nieokreślono"}
                  </Typography>
                }
              />
              <ListItemText
                primary="Górna granica"
                secondary={
                  <Typography className={classes.listText}>
                    {config.upperTreeDensityRate > 0
                      ? config.upperTreeDensityRate
                      : "Nieokreślono"}
                  </Typography>
                }
              />
              <DeleteIcon
                className={classes.delete}
                onClick={() => {
                  let filtered = configs.filter((e, i) => {
                    if (i === idx) return false;
                    else return true;
                  });
                  setConfigs(filtered);
                }}
              />
            </ListItem>
            <Divider />
          </List>
        ))}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                name="manual"
                checked={isHarvestManual}
                onChange={handleHarvestingSwitch}
                color="primary"
              />
            }
            label="Zbiory ręczne"
          />
          <FormControlLabel
            control={
              <Switch
                name="combine"
                checked={!isHarvestManual}
                onChange={handleHarvestingSwitch}
                color="primary"
              />
            }
            label="Zbiory maszynowe"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="number"
          label="Wpisz zalecaną ilość uli"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="1"
          variant="outlined"
          fullWidth
          required
          value={values.suggestedHivesCount}
          onWheel={(e) => e.target.blur()}
          onChange={handleChange("suggestedHivesCount")}
          error={errorHives}
          helperText={errorHives ? CULTIVATION_TYPE_ERRORS.INVALID_VALUE : ""}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6">Ilość drzew</Typography>
        <Typography variant="subtitle1">
          (pozostaw puste, jeśli jedna z granic nie istnieje)
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        spacing={1}
        justifyContent="space-around"
      >
        <Grid item xs={12} sm={5}>
          <TextField
            type="number"
            className={classes.area}
            label="Dolna granica"
            InputLabelProps={{ classes: { root: classes.label } }}
            variant="outlined"
            value={values.lowerDensityRate}
            onWheel={(e) => e.target.blur()}
            onChange={handleChange("lowerDensityRate")}
            error={errorLower}
            helperText={errorLower ? CULTIVATION_TYPE_ERRORS.INVALID_VALUE : ""}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={2}>
            <Typography variant="body1">-</Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={5}>
          <TextField
            type="number"
            className={classes.area}
            label="Górna granica"
            InputLabelProps={{ classes: { root: classes.label } }}
            variant="outlined"
            value={values.upperDensityRate}
            onWheel={(e) => e.target.blur()}
            onChange={handleChange("upperDensityRate")}
            error={errorUpper}
            helperText={errorUpper ? CULTIVATION_TYPE_ERRORS.INVALID_VALUE : ""}
          />
        </Grid>
      </Grid>
      {incorrectRange && (
        <Typography className={classes.error} style={{ marginTop: 5 }}>
          Górna granica mniejsza lub równa dolnej!
        </Typography>
      )}
      {configRangeError && (
        <Typography className={classes.error} style={{ marginTop: 5 }}>
          {configRangeErrorMessage}
        </Typography>
      )}
      <Grid item xs={12}>
        <Box className={classes.buttonBox}>
          <CustomButton
            disabled={
              incorrectRange ||
              values.name === "" ||
              values.suggestedHivesCount === "" ||
              (!values.upperDensityRate && !values.lowerDensityRate)
            }
            variant={
              incorrectRange ||
              values.name === "" ||
              values.suggestedHivesCount === "" ||
              (!values.upperDensityRate && !values.lowerDensityRate)
                ? BUTTON_VARIANTS.DISABLED
                : BUTTON_VARIANTS.GREEN
            }
            size={BUTTON_SIZES.LARGE}
            clickHandler={addConfig}
            text="Dodaj konfigurację"
          />
        </Box>
        {(noConfigsError || !configs.length) && (
          <Typography
            className={classes.error}
            style={{ marginTop: 20, marginBottom: -30 }}
          >
            Proszę dodać przynajmniej jedną konfigurację
          </Typography>
        )}
        {values.name === "" && (
          <Typography
            className={classes.error}
            style={{ marginTop: 30, marginBottom: -30 }}
          >
            Podaj nazwę typu uprawy!
          </Typography>
        )}
      </Grid>
    </CommonModal>
  );
};

export default AddCultivationTypeWindow;
