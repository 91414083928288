const styles = {
  container: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    height: "100%",
    width: "100%",
  },
  loader: {
    "justify-content": "center",
    "align-items": "center",
  },
};

export default styles;
