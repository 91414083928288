import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  Grid,
  makeStyles,
  ThemeProvider,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";

import theme from "../../components/theme/theme";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import UserList from "../../components/UserInfoList/UserInfoList";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import NewUserWindow from "../../components/ModalWindows/UserInfo/NewUserWindow";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import { searchConstants } from "../../constants/localStorage";
import styles from "./UserInfoScreen.styles";
import { useGetUsers } from "../../query/useUserQuery";

const useStyles = makeStyles(styles);

const UserInfoScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = useState(
    localStorage.getItem("currentUserInfoListPage")
      ? Number(localStorage.getItem("currentUserInfoListPage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("currentUserInfoListRowsPerPage")
      ? Number(localStorage.getItem("currentUserInfoListRowsPerPage"))
      : 10
  );
  const [search, setSearch] = useState(
    localStorage.getItem(searchConstants.users)
      ? localStorage.getItem(searchConstants.users)
      : ""
  );

  const handleError = useCallback(
    (err) => {
      setError(true);
      if (err.response?.status === 403) {
        setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
        setTimeout(() => {
          history.push("/logout");
        }, 2000);
      } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_USERS);
    },
    [history]
  );

  const handleAddError = useCallback(
    (err) => {
      setError(true);
      if (err.response?.status === 403) {
        setErrorText(SERVER_ERROR_MESSAGES.INSUFFICIENT_CREDENTIALS);
        setTimeout(() => {
          history.push("/logout");
        }, 2000);
      } else setErrorText(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_USER);
    },
    [history]
  );

  const { users, amount, isFetchingUsers } = useGetUsers(
    page + 1,
    rowsPerPage,
    handleError
  );

  const handleChange = (e) => {
    localStorage.setItem(searchConstants.users, e?.target.value?.toLowerCase());
    setSearch(e?.target.value?.toLowerCase());
    if (e && e.target.value !== "") setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {!isFetchingUsers || open ? (
        <Grid container>
          <ListHeader
            variant="title-add-search2"
            title="Lista użytkowników"
            handleAdd={() => setOpen(true)}
          />
          <Grid container className={classes.search}>
            <StyledFormTextField
              fullWidth={true}
              label="Szukaj po adresie email..."
              variant="outlined"
              shrink="true"
              defaultValue={
                localStorage.getItem(searchConstants.users) === "undefined" ||
                !localStorage.getItem(searchConstants.users)
                  ? ""
                  : localStorage.getItem(searchConstants.users)
              }
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} align="center">
              <UserList
                users={users?.filter((u) =>
                  u.username.toLowerCase().startsWith(search.toLowerCase())
                )}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component={"div"}
                count={amount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
          <NewUserWindow
            isOpen={open}
            close={() => setOpen(false)}
            handleError={handleAddError}
          />
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default UserInfoScreen;
