import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { qrCodes } from "../utils/api";
import { queryKeys } from "../constants/queryKeys";

export const useGetQR = (page, limit, handleError) => {
  const { data, isFetching: isFetchingQR } = useQuery({
    queryKey: [queryKeys.QR_CODES, page],
    queryFn: () => qrCodes.getPage(page, limit),
    onSuccess: () => {},
    onError: (err) => {
      handleError(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingQR,
    amount: data?.data.qrCodesAmount,
    qrs: data?.data.qrCodeResponseList,
  };
};

export const useGetOneQR = (qrCodeId, handleError) => {
  const {
    data,
    isFetching: isFetchingQR,
    isError,
  } = useQuery({
    queryKey: [queryKeys.ONE_QR_CODE],
    queryFn: () => qrCodes.one(qrCodeId),
    onSuccess: () => {},
    onError: (err) => {
      handleError(err);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  return {
    isFetchingQR,
    isError,
    qr: data?.data,
  };
};

export const useAddQR = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: addQR, isLoading: isUpdatingQR } = useMutation(
    qrCodes.add,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === queryKeys.QR_CODES,
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.ONE_QR_CODE],
        });
      },
      onError: (err) => {
        handleError && handleError(err);
      },
    }
  );
  return {
    addQR,
    isUpdatingQR,
  };
};

export const useEditQR = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: editQR, isLoading: isUpdatingQR } = useMutation(
    qrCodes.edit,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === queryKeys.QR_CODES,
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.ONE_QR_CODE],
        });
      },
      onError: (err) => {
        handleError && handleError(err);
      },
    }
  );
  return {
    editQR,
    isUpdatingQR,
  };
};

export const useDeleteQR = (handleError) => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteQR, isLoading: isUpdatingQR } = useMutation(
    qrCodes.delete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === queryKeys.QR_CODES,
        });
        queryClient.invalidateQueries({
          queryKey: [queryKeys.ONE_QR_CODE],
        });
      },
      onError: (err) => {
        handleError && handleError(err);
      },
    }
  );
  return {
    deleteQR,
    isUpdatingQR,
  };
};
