import { makeStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    display: "flex",
  },
  iconLabel: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
  },
  delete: {
    color: colors.carmine,
  },
  iconLabelRed: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
    color: colors.carmine,
  },
  cell: {
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
  },
  buttonCellClickable: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f7f7f7",
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
    borderRadius: 5,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#d5d9d9",
    },
  },
  buttonCell: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    margin: 0,
    borderRadius: 5,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#d5d9d9",
    },
  },
}));

export default useStyles;
