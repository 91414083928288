import React from "react";
import { Grid, Typography, TableCell } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Delete as DeleteIcon } from "@material-ui/icons";

import useStyles from "./DeleteEditButton.styles";

const DeleteEditButton = ({
  mobile,
  editClickHandler,
  deleteClickHandler,
  editText = "EDYTUJ",
  deleteText = "USUŃ",
  editOnly = false,
  deleteOnly = false,
}) => {
  const classes = useStyles();

  let content = (
    <Grid
      container
      spacing={2}
      direction="row"
      alignContent="center"
      justifyContent="center"
    >
      {!deleteOnly && (
        <Grid item>
          <div className={classes.headerCell} onClick={editClickHandler}>
            <EditIcon />
            <Typography className={classes.iconLabel}>{editText}</Typography>
          </div>
        </Grid>
      )}
      {!editOnly && (
        <Grid item>
          <div className={classes.headerCell} onClick={deleteClickHandler}>
            <DeleteIcon className={classes.delete} />
            <Typography className={classes.iconLabelRed}>
              {deleteText}
            </Typography>
          </div>
        </Grid>
      )}
    </Grid>
  );

  if (!mobile) {
    content = (
      <TableCell className={classes.cell} align="left">
        <Grid container spacing={2} alignItems="center">
          {!deleteOnly && (
            <Grid item md={12}>
              <div
                className={classes.buttonCellClickable}
                onClick={editClickHandler}
              >
                <EditIcon />
                <Typography className={classes.iconLabel}>
                  {editText}
                </Typography>
              </div>
            </Grid>
          )}
          {!editOnly && (
            <Grid item md={12}>
              <div
                className={classes.buttonCellClickable}
                onClick={deleteClickHandler}
              >
                <DeleteIcon className={classes.delete} />
                <Typography className={classes.iconLabelRed}>
                  {deleteText}
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
      </TableCell>
    );
  }

  return <>{content}</>;
};

export default DeleteEditButton;
