import React, { useMemo } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import CommonModal from "../../common/modals/CommonModal";

import styles from "./UserModal.styles";
import useForm from "../../../hooks/useForm";
import { MODAL_TYPE, COMMON_MODAL_TEXTS } from "../../../constants/dictionary";
import { useAddUser } from "../../../query/useUserQuery";
import { validateEmail, validatePassword } from "../../../utils/validation";

const useStyles = makeStyles(styles);

const NewUserWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const { handleChange, values } = useForm({
    password: "",
    email: "",
  });

  const emailError = useMemo(
    () => !validateEmail(values.email),
    [values.email]
  );
  const passwordError = useMemo(
    () => !validatePassword(values.password),
    [values.password]
  );

  const submit = (e) => {
    e.preventDefault();
    if (emailError || passwordError) return;
    addUser({ username: values.email, password: values.password });
    close();
  };

  const { addUser } = useAddUser(handleError);

  return (
    <CommonModal
      disabled={emailError || passwordError}
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_USER_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              label="Email"
              variant="outlined"
              required
              shrink="true"
              onChange={handleChange("email")}
              autoComplete="off"
              autoCorrect="off"
              error={emailError && values.email}
              helperText={
                emailError && values.email ? "Niepoprawny adres e-mail." : ""
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              label="Hasło"
              variant="outlined"
              required
              shrink="true"
              onChange={handleChange("password")}
              autoComplete="off"
              autoCorrect="off"
              error={passwordError && values.password}
              helperText={
                passwordError
                  ? "Hasło musi się składać z przynajmniej 8 znaków, w tym jednej dużej litery, jednej cyfry i jednego znaku specjalnego."
                  : ""
              }
            />
          </Box>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default NewUserWindow;
