import back from "../../assets/images/background.jpg";

import colors from "../../constants/colors";

const styles = {
  container: {
    "background-image": `url(${back})`,
    "min-height": "100%",
    "min-width": "100%",
    position: "absolute",
    "background-size": "cover",
    top: 0,
    left: 0,
  },
  box: {
    margin: "20px",
    display: "inline",
  },
  header: {
    color: colors.white,
    "font-family": "Montserrat",
    fontWeight: "bold",
  },
};

export default styles;
