import React from "react";
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Grid,
  ThemeProvider
} from "@material-ui/core";
import theme from "./LoginSreen.breakpoints.js";
import LoginForm from "../../components/LoginForm/LoginForm.js";
import styles from "./LoginScreen.styles.js";

const useStyles = makeStyles(styles);
const LoginScreen = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <Container align="center" fixed className={classes.container}>
            <Box className={classes.box}>
              <Typography variant="h4" className={classes.header}>
                PANEL LOGOWANIA DO SYSTEMU
              </Typography>
            </Box>
            <LoginForm />
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginScreen;
